import React, { useEffect, useState } from 'react'

const Blogs = () => {

    const [posts, setPosts] = useState([]);
  
  
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const response = await fetch('https://blogs.iscreening.in/wp-json/wp/v2/posts');
        
          const data = await response.json();
          console.log("responseeeee",data);
          setPosts(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } 
      };
  
      fetchPosts();
    }, []);
  
   
  
    return (
      <div>
        <h1>Posts</h1>
        {posts.length === 0 ? (
          <p>No posts found</p>
        ) : (
          <ul>
            {posts.map(post => (
              <li key={post.id}>
                <h2>{post.title.rendered}</h2>
             
                <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
export default Blogs