import React from 'react'
import logo from "../iscreening-logo-01.png"
const Home = () => {
  return (
    <>
        <div class="content">
        <div class="logo">
          <img src={logo} alt="Logo" width='150px'/>
        </div>
        <h1>Coming Soon</h1>
        <p style={{marginTop: "20px"}}>Contact us: +91 72594 54868</p>
        <p>For sales: +91 97391 62573 / +91 8826515000</p>
        <p>Email us: preethi.m@iscreening.in / dsingh@iscreening.in</p>
      
        <div class="social-icons">
        </div>
        </div>
    </>
  )
}

export default Home