import { Route, Routes } from 'react-router-dom';
import Blogs from './Pages/Blogs';

import Home from './Pages/Home';
function App() {
  return (
    <>
     <Routes>
     <Route exact  path="/" element={<Home/>} />
          <Route exact path="/blogs" element={<Blogs/>} />
         
        </Routes>
      
       
    </>
  );
}

export default App;
